/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
@import '/src/app/app-menu/app-menu.component.scss';
@import '/src/app/app.component.scss';

// Font Awesome
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
// MDB SCSS
@import "~mdb-angular-ui-kit/assets/scss/mdb.scss";

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
